var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-hero-section dn-container" },
    [
      _c("carousel", {
        attrs: {
          title: "Announcements",
          type: "hero",
          overflow: true,
          items: _vm.banners,
          perView: 3.2,
          breakpoints: _vm.breakpoints,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }